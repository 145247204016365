@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&family=Raleway:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@500&display=swap');

@layer base {
    body {
        @apply font-[Raleway]
    }
    li{
        @apply px-4;
        @apply cursor-pointer;
    }
}

.content-div {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 250px;
}

.content-div:hover {
    background-image: linear-gradient(
        to right, 
        rgba(112, 157, 255, 0.8),
        hsla(242, 74%, 61%, 0.8)
    )!important;
    background-size: cover;
}

/* This changes all the animations globally */

.first{
    --animate-delay: 0.3s;
    --animate-duration: 800ms;
}
.second{
    --animate-delay: 0.50s;
    --animate-duration: 1600ms;
}
.third{
    --animate-delay: 0.7s;
}
.fourth{
    --animate-delay: 0.9s;
}
.fifth{
    --animate-delay: 0.11s;
}

::-webkit-scrollbar {
    display: none;
  }

.remove-fill{
    animation-fill-mode: none !important;
}

.space-font{
    font-family: 'Space Grotesk', sans-serif;
}
/* Customize scrollbar */
/* .scrollbar::-webkit-scrollbar {
    background-image: linear-gradient(180deg, #D0368A 0%, #708AD4 99%) !important;
    box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5) !important;
    border-radius: 100px !important;
  }

.scrollbar::-webkit-scrollbar-track {
    background-image: linear-gradient(180deg, #D0368A 0%, #708AD4 99%) !important;
    box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5) !important;
    border-radius: 100px !important;
}

.scrollbar::-webkit-scrollbar-thumb {
    background-image: linear-gradient(180deg, #D0368A 0%, #708AD4 99%) !important;
    box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5) !important;
    border-radius: 100px !important;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
    background: #c0a0b9;
} */